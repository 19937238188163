import "../css/StageBox2.css"

import Linkify from "linkify-react"
import { useState, useEffect } from 'react'

import { friendlyTimeInterval } from "../lib/Util"
import UsernameLink from "./UsernameLink"
import UserpicLink from "./UserpicLink"

function CloneMessage({ message, cloner, isAuthorsNote, isLinked }) {
  // const [justText, setJustText] = useState(false)
  // const [timestampComponent, setTimestampComponent] = useState(null)

  // // Otherwise state mismatch on production because of different time calculations
  // useEffect(() => {
  //   const friendlyTimestamp = friendlyTimeInterval(clonedAt, justText)

  //   const friendlyTimestampComponent = isLinked ?
  //     <a onClick={() => (setJustText(!justText))}>{friendlyTimestamp}</a> :
  //     friendlyTimestamp

  //   setTimestampComponent(friendlyTimestampComponent)
  // }, [clonedAt, justText])

  return (
    <div className="sb2">
      <div className="stage clone-message">
        <div className="content">
          <div className="noteText">
            <div className="heading">
              { isAuthorsNote ?
                <div className="title">Author's Note</div> :
                <div className="title">
                  Note by <UsernameLink user={cloner} withBy={false} isLinked={isLinked} />
                </div>
              }
            </div>
            <div className="note-actual-text">
              {message}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className="sb">
  //     <div className="stage clone-message">
  //       <div className="content">
  //         { cloner &&
  //           <div className="facebox">
  //             <UserpicLink user={cloner} isLinked={isLinked} />
  //           </div>
  //         }
  //         <div className="note-header">
  //           <div className="title">
  //             Note:
  //           </div>
  //           <div className="username-link">
  //             <span className="user-name">{cloner.name}</span>
  //           </div>
  //           <div className="timestamp">
  //             {clonedAt && timestampComponent}
  //           </div>
  //         </div>
  //         <div className="noteText">
  //           { isLinked ? <Linkify options={ {target: "_blank"} }>{message}</Linkify> : message }
  //         </div>
  //        </div>
  //    </div>
  //   </div>
  // )
}

export default CloneMessage
